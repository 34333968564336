import React from "react";
import { view } from "@risingstack/react-easy-state";
import moment from "moment";

import interfaceState from "../../store/interfaceState";
import tableState from "../../store/tableState";

function Table() {
  function tableBond(bond, i) {
    const maxEllipsis = {
      maxWidth: 150,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };

    

    return (
      <tr key={i} className={`${i % 2 ? 'bg-gray-50' : 'bg-white'}`}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {moment(bond.dtmDate).format("MMM DD YYYY").toUpperCase()}
        </td>
        <td  style={tableState.scrollable ? null : maxEllipsis} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {bond.strBondType}
        </td>
        <td  style={tableState.scrollable ?  null : maxEllipsis} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {bond.strPermitType}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {bond.sngAmount} 
        </td>
        <td  style={tableState.scrollable ?  null : maxEllipsis} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {bond.strPrincipal}
        </td>
        <td style={tableState.scrollable ?  null : maxEllipsis} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {bond.strTName}
        </td>
        <td style={tableState.scrollable ?  null : maxEllipsis} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {bond.strIndemnitor}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <button
            onClick={() => { interfaceState.setBond(bond) ;interfaceState.setDetails(true)}}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Details
          </button>
        </td>
      </tr>
    );
  }
  const bondTypeMinW = {
    minWidth: 125,
  }
  return (
    <div className="flex flex-col max-w-screen-xl mx-auto">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    style={bondTypeMinW}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Bond type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Permit Type/Obligee
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Principal
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 font-bold uppercase tracking-wider"
                  >
                    Indemnitor
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableState.showTable.map((bond, i) => {
                  return tableBond(bond, i);
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default view(Table);
