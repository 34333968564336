import React from "react";
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";
import { AuthProvider } from "../utils/Auth";
import Layout from "../components/layout/index";
import PrivateRoute from "../utils/PrivateRoute";
import AuthScene from "../components/authScene";
import NoMatch from './NoMatch';
import Notification from '../components/Notification';

const Routes = () => {
  return (
    <HashRouter>
      <Notification/>
      <AuthProvider>
        <Switch>
          <Route path="/auth" component={AuthScene} />
          <PrivateRoute path="/app" component={Layout} />
          <Route exact path="/">
            <Redirect to="/auth" />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </AuthProvider>
    </HashRouter>
  );
};

export default Routes;



