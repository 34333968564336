import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyD3OoK-MT3YR1iN40e-NOFfUdCTS0UWYZ0",
  authDomain: "surey-wld.firebaseapp.com",
  projectId: "surey-wld",
  storageBucket: "surey-wld.appspot.com",
  messagingSenderId: "1001188743088",
  appId: "1:1001188743088:web:7acd02801dc2bd7512e875",
  measurementId: "G-90N5M6B3XC"
};
firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);


export default firebase;