import React from "react";

export default function Search({ variable, action, label }) {

  function isNumber() {
    if (label === 'Bond Amount' || label === 'Other' || label === 'Premium' || label === 'Total Premium') {
      return true
    } else {
      return false
    }
  }

  function formatPrice(price) {
    if (!price) {
      return "";
    }
    let withDecimals = price?.toLocaleString('en-US', {maximumFractionDigits:2});
    console.log(withDecimals);
    let withDollar = '$' + withDecimals;
    return withDollar;
  }

  return (
    <div className="block relative mt-4 w-11/12 mx-1 sm:mx-8">
      <label
        style={{
          marginTop: -10,
          marginLeft: 15,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        className="absolute bg-white block text-gray-700 text-sm font-medium mb-2"
      >
        {label}
      </label>
      <input
        type={'text'}
        onChange={(e) => action(e.target.value)}
        className="appearance-none shadow-sm appearance-none border border-gray-300 rounded-md w-full py-3 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:border-blue-300"
        placeholder=""
        defaultValue={isNumber() ? formatPrice(variable) : variable}
      />
    </div>
  );
}
