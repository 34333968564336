import React, { useState } from "react";
import { Route, Redirect} from "react-router-dom";
import { Transition } from "@tailwindui/react";
import { view } from "@risingstack/react-easy-state";

import Panel from "../Panel";
import firebase from "../../utils/firebase";
import logo from "../../assets/images/logoHorizontal.png";
import fb from "../../utils/firebase";
import interfaceState from '../../store/interfaceState';
import DeleteModal from "../DeleteModal";

function Layout() {
  const [profileMenu, setProfileMenu] = useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-cool-gray-100">
      {interfaceState.deletedModalVal && <DeleteModal />}
      <div className="flex-1 overflow-auto focus:outline-none" tabIndex="0">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <div className="flex flex-1">
              <img src={logo} alt="logo" className="h-full p-1 hidden sm:block" />{" "}
            </div>
            <button
            onClick={() => interfaceState.setAdd(!interfaceState.showAdd)}
              className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100"
              id="user-menu"
              aria-label="User menu"
              aria-haspopup="true"
            >
              <p className="hover:bg-blue-700 ml-3 text-white bg-blue-800 w-8 rounded-full text-xl leading-normal font-medium lg:block">
                +
              </p>
              <p className="hover:text-blue-500 ml-3 text-blue-800 text-sm leading-5 font-medium lg:block">
                Add
              </p>
            </button>

            <div className="ml-4 flex items-center md:ml-6">
              <div className="ml-3 relative">
                <div>
                  <button
                    onClick={() => setProfileMenu(!profileMenu)}
                    className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                  >
                    <p className="ml-3 text-cool-gray-700 text-sm leading-5 font-medium lg:block">
                      {fb.auth().currentUser.email}{" "}
                    </p>
                    <svg
                      className="flex-shrink-0 ml-1 h-5 w-5 text-cool-gray-400 lg:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <Transition
                  show={profileMenu}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {/* Will fade in and out */}
                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                    <div
                      className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <button
                        onClick={() => firebase.auth().signOut()}
                        
                        className="w-full text-left block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
                        role="menuitem"
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
        <Route path="/app/home" component={Panel} />
        {/* <Route path="/app/moviles" component={Moviles} /> */}

        <Redirect from="/app" to="/app/home" />
      </div>
    </div>
  );
}

export default view(Layout);
