import React, { useState, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";
import { Transition } from "@tailwindui/react";

import Table from "../Table";
import AdvancedFilter from "../AdvancedFilters";
import AddNew from "../AddNew";
import Details from "../Details";
import Edit from "../Edit";
import interfaceState from "../../store/interfaceState";
import tableState from "../../store/tableState";
import { getTblBonds } from "../../api/requests/tblBonds";

function loadingIndicator() {
  return (
    <svg
      className="animate-spin  h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

function Panel() {
  const [showOptions, setOptions] = useState(false);
  const [selectedParameter, setSelectedParam] = useState("Bond Number");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    async function fetchData() {
      let data = { filters: {}};
      let response = await getTblBonds(data);
      tableState.setFetchedBonds(response.data);
    }
    fetchData();
  }, []);

  async function handleSearch() {
    let key;
    if (selectedParameter === "Bond Number") {
      key = "strBondNo";
    }
    if (selectedParameter === "Principal") {
      key = "strPrincipal";
    }
    if (selectedParameter === "Trade Name") {
      key = "strTName";
    }
    if (selectedParameter === "Indemnitor") {
      key = "strIndemnitor";
    }

    try {
      let data = { filters: {[key]: query} };
      if (query === "") {
        data = { filters: {}};
      }
      let response = await getTblBonds(data);
      tableState.setFetchedBonds(response.data);
      setLoading(false);
      interfaceState.showNotification({
        show: true,
        type: "success",
        message: `Search complete! Found ${response.data.length} bonds`,
      });
    } catch (e) {
      interfaceState.showNotification({
        show: true,
        type: "error",
        message: "There seems to be an error in the network",
      });
      setLoading(false);
    }
  }

  function downloadItems() {
    const items = tableState.fetchedResults;
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')


    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const blob = new Blob([csv], { type: 'text/csv' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `searchResults.csv`);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `searchResults.csv`);
        fakeLink.click();
    }
  
  }

  return (
    <div>
      {interfaceState.showAdd && <AddNew />}
      {interfaceState.showAdvanced && <AdvancedFilter />}
      {interfaceState.showDetails && <Details />}
      {interfaceState.showEdit && <Edit />}

      {interfaceState.showMain && (
        <div>
          <div className="flex flex-row pb-8 z-0 max-w-screen-xl mx-auto mt-8 space-even mx-auto">
            <div className="w-8/12 block  px-4 sm:px-6 lg:px-8">
              <div className="relative flex">
                <label
                  style={{
                    marginTop: -10,
                    marginLeft: 15,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                  className="absolute bg-white block text-gray-700 text-sm font-medium mb-2"
                  htmlFor="username"
                >
                  Search for
                </label>
                <div
                  onClick={() => setOptions(!showOptions)}
                  className="w-48 cursor-pointer flex shadow-sm appearance-none border border-gray-300 rounded-tr-none rounded-br-none rounded-md py-3 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:border-blue-300 justify-around"
                >
                  {selectedParameter}
                  <svg
                    className="flex-shrink-0 ml-1 h-5 w-5 text-cool-gray-400 lg:block"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  onClick={() => setOptions(false)}
                  value={query}
                  onChange={(e) => setQuery(e.currentTarget.value)}
                  className="w-9/12 border-l-0 flex shadow-sm appearance-none border rounded-bl-none rounded-tl-none border-gray-300 rounded-md  py-3 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:border-blue-300"
                ></input>
              </div>

              <Transition
                show={showOptions}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className="z-10 absolute  mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className="py-1">
                    <button
                      onClick={() => {
                        setSelectedParam("Bond Number");
                        setOptions(false);
                      }}
                      className="block pl-4 pr-7 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Bond Number
                    </button>
                    <button
                      onClick={() => {
                        setSelectedParam("Principal");
                        setOptions(false);
                      }}
                      className="block pl-4 pr-16 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Principal
                    </button>
                    <button
                      onClick={() => {
                        setSelectedParam("Trade Name");
                        setOptions(false);
                      }}
                      className="block pl-4 pr-10 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Trade Name
                    </button>
                    <button
                      onClick={() => {
                        setSelectedParam("Indemnitor");
                        setOptions(false);
                      }}
                      className="block pl-4 pr-12 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Indemnitor
                    </button>
                  </div>
                </div>
              </Transition>
            </div>
            <div onClick={() => downloadItems()} className="flex cursor-pointer">
              <div className="py-2">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  ></path>
                </svg>
              </div>
              <p className="py-2 mx-6">Download</p>
            </div>
            <button
              type="button"
              className="w-2/12 justify-center inline-flex items-center py-2 px-8  border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition ease-in-out duration-150"
              onClick={() => {
                setLoading(true);
                handleSearch();
              }}
            >
              {loading ? loadingIndicator() : "SEARCH"}
            </button>

            <button
              onClick={() => {
                setOptions(false);
                interfaceState.setAdvanced(!interfaceState.showAdvanced);
              }}
              className="w-2/12 max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100"
              id="user-menu"
              aria-label="User menu"
              aria-haspopup="true"
            >
              <p className="ml-3 text-blue-700 text-sm leading-5 font-medium lg:block">
                More filters
              </p>
              <svg
                className="flex-shrink-0 ml-1 h-5 w-5 text-blue-700 lg:block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <Table />

          <div className="mt-3 max-w-screen-xl mx-auto bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => tableState.pagePrevious()}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </button>
              <button
                onClick={() => tableState.pageNext()}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing
                  <span className="font-medium">{` ${tableState.currentPage * 10 - 9} `}</span>
                  to
                  <span className="font-medium">
                    {tableState.currentPage === tableState.pageCount
                      ? ` ${tableState.fetchedResults.length} `
                      : ` ${tableState.currentPage * 10} `}
                  </span>
                  of
                  <span className="font-medium">{` ${tableState.fetchedResults.length} `}</span>
                  results
                </p>
              </div>
              <div>
                <label className="mr-6">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value={tableState.scrollable}
                    onChange={(e) => tableState.setScrollable(e.target.checked)}
                  />
                  <span className="text-sm font-medium text-blue-600 ">
                    Scrollable
                  </span>
                </label>
                <nav
                  className="relative z-0 inline-flex shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <button
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    onClick={() => tableState.pagePrevious()}
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                    {tableState.currentPage}
                  </button>
                  <button
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    onClick={() => tableState.pageNext()}
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default view(Panel);
