import React, {useState} from 'react';
import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import Loading from './Loading';

function AuthScene() {

    const [authComponent, setAuthComponent] = useState(3);
 

    let showAuthComponent = <></>;
    if (authComponent=== 0){
        showAuthComponent = <Login setAuthComponent={setAuthComponent}/>
    }
    if (authComponent === 1){
        showAuthComponent = <Register setAuthComponent={setAuthComponent} />
    }
    if (authComponent === 2) {
        showAuthComponent = <Forgot setAuthComponent={setAuthComponent} />
    }
    if (authComponent === 3) {
        showAuthComponent = <Loading setAuthComponent={setAuthComponent} />
    }

    return (
    showAuthComponent
    );
}

export default AuthScene;