import React from "react";
import moment from "moment";

function Detail({ variable, label }) {
  function isNumber() {
    if (
      label === "Bond Amount" ||
      label === "Other" ||
      label === "Premium" ||
      label === "Total Premium"
    ) {
      return true;
    } else {
      return false;
    }
  }

  function formatPrice(price) {
    console.log(price);
    if (!price) {
      return "";
    }
    let withDecimals = price?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
    let withDollar = "$" + withDecimals;
    return withDollar;
  }

  let valueIsDate = variable;
  if (label === "Date") {
    valueIsDate = moment(variable).format("MMM DD YYYY").toUpperCase();
  }
  if (isNumber()) {
    return (
      <div className="break-all mt-4 sm:mt-1 sm:w-1/4 w-full">
        <div className=" text-blue-600">{label}</div>
        {formatPrice(variable)}
      </div>
    );
  } else {
    return (
      <div className="break-all mt-4 sm:mt-1 sm:w-1/4 w-full">
        <div className=" text-blue-600">{label}</div>
        {valueIsDate}
      </div>
    );
  }
}

export default Detail;
