import React from "react";
import logo from "../assets/images/logoWname.png";
import background from "../assets/images/background.png";

export default function NoMatch() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <img
        className={"absolute z-0 min-w-full min-h-full bg-cover bg-center"}
        src={background}
        alt="AAA Surety background"
      />
      <div className="z-10 mr-auto p-16 ml-auto shadow rounded-xl bg-white">
        <div className="w-full max-w-md mr-auto ml-auto mb-1 text-center">
          <img className={"w-48 mx-auto"} src={logo} alt="AAA Surety Logo" />
        </div>
        <div className="bg-white overflow-hidden  mt-4">
          <div className="px-4 py-5 sm:p-6">404 Page not found</div>
        </div>
      </div>
    </div>
  );
}
