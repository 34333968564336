import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";
import Layout from '../components/layout/index';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const {currentUser} = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={routeProps =>
              !!currentUser ? <Layout {...routeProps} /> : <Redirect to={"/auth"} />
            }
        />
    );
};

export default PrivateRoute;

