import React, { useEffect, useState } from "react";
import app from "./firebase.js";
import { withRouter, useHistory } from "react-router-dom";
import authState from "../store/authState";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    app.auth().onAuthStateChanged(function (user) {
      if (user) {
        setCurrentUser(user);
        history.push("/app/home");
      } else {
        setCurrentUser(false);
        authState.setCurrentUser(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default withRouter(AuthProvider);
