import { store } from "@risingstack/react-easy-state";

const tableState = store({
  showTable: [],
  fetchedResults: [],
  currentPage: 1,
  pageCount: 1,
  scrollable: false,

  setScrollable(bool) {
    tableState.scrollable = bool;
  },

  setFetchedBonds(bonds) {
    tableState.showTable = bonds.slice(0, 10);
    tableState.fetchedResults = bonds;
    tableState.pageCount = Math.round(tableState.fetchedResults.length / 10);
    tableState.currentPage = 1;
  },

  pageNext() {
    if (tableState.currentPage + 1 > tableState.pageCount) {
    } else {
      tableState.currentPage = tableState.currentPage + 1;
      let index = tableState.currentPage - 1;
      tableState.showTable = tableState.fetchedResults.slice(index * 10, index * 10 + 10);
    }
  },

  pagePrevious() {
    if (tableState.currentPage === 1) {
    } else {
      tableState.currentPage = tableState.currentPage - 1;
      let index = tableState.currentPage - 1;
      tableState.showTable = tableState.fetchedResults.slice(index * 10, index * 10 + 10);    }
  },
});

export default tableState;
