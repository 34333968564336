import { store } from "@risingstack/react-easy-state";

const interfaceState = store({
  showNotificationVal: false,
  notificationMessage: "",
  notificationType: "success",
  showAdd: false,
  showAdvanced: false,
  showMain: true,
  showDetails: false,
  showEdit: false,
  deletedModalVal: false,
  selectedBond: null,
  bond: {},

  setSelectedBond(bondId) {
    interfaceState.selectedBond = bondId;
  },

  showDeleteModal(bool) {
    interfaceState.deletedModalVal = bool;
  },

  updateBond(bondUpdates){
    let result = Object.assign(interfaceState.bond, bondUpdates);
    console.log(result);
    interfaceState.setBond(result);
  },

  setBond(bond) {
   interfaceState.bond = bond; 
  },

  setMain(bool) {
    interfaceState.showMain = bool;
  },

  setEdit(bool) {
    interfaceState.showEdit = bool;
    if (bool) {
    interfaceState.showAdvanced = false;
    interfaceState.showMain = false;
    interfaceState.showDetails = false;
    } else {
      interfaceState.showDetails = true;
    }
  },

  setAdd(bool) {
    interfaceState.showAdd = bool;
    if (bool) {
    interfaceState.showAdvanced = false;
    interfaceState.showMain = false;
    interfaceState.showDetails = false;
    interfaceState.showEdit = false;
    } else {
      interfaceState.showMain = true;
    }
  },
  setAdvanced(bool) {
    interfaceState.showAdvanced = bool;

    if (bool) {
      interfaceState.showAdd = false;
      interfaceState.showMain = false;
      interfaceState.showDetails = false;
      } else {
        interfaceState.showMain = true;
      }
  },

  setDetails(bool) {
    interfaceState.showDetails = bool;

    if (bool) {
      interfaceState.showAdd = false;
      interfaceState.showMain = false;
      interfaceState.showAdvanced = false;
      } else {
        interfaceState.showMain = true;
        interfaceState.showDetails = false;
      }
  },

  dissapearNotification: () => (interfaceState.showNotificationVal = false),

  showNotification(data) {
    interfaceState.showNotificationVal = data.show;
    interfaceState.notificationMessage = data.message;
    interfaceState.notificationType = data.type;
  },
});

export default interfaceState;
