import React from "react";
import moment from "moment";

import interfaceState from "../../store/interfaceState";
import Detail from "./Detail";

function Details() {
  const { bond } = interfaceState;

  function handleEdit(item) {
    interfaceState.setEdit(true);
  }

  console.log(bond);
  return (
    <div className="flex flex-col max-w-screen-xl mx-auto">
      <nav
        className="cursor-pointer flex my-8 ml-8"
        onClick={() => interfaceState.setDetails(false)}
        aria-label="Breadcrumb"
      >
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <button className="text-gray-400 hover:text-gray-500">
                <svg
                  className="flex-shrink-0 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <button className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                Back to dashboard
              </button>
            </div>
          </li>
        </ol>
      </nav>
      <div className="">
        <div className="flex sm:flex-row flex-col justify-between mx-2 sm:mx-20 border-b-2 pb-2 mb-4 border-gray-400">
          <h1 className="font-bold py-2">Details</h1>
          <div className="flex items-center flex-col sm:flex-row">
            <div onClick={() => window.print()} className="flex cursor-pointer">
              <div className="py-2">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  ></path>
                </svg>
              </div>
              <p className="py-2 mx-6">Download</p>
            </div>
            <button
              className="hover:bg-red-400 px-16 py-2 mr-2  bg-red-500 text-white rounded"
              onClick={() => interfaceState.showDeleteModal(true)}
            >
              DELETE
            </button>
            <button
              className="hover:bg-blue-400 px-20 py-2 bg-blue-500 text-white rounded"
              onClick={() => handleEdit()}
            >
              EDIT
            </button>
          </div>
        </div>
        <div className="flex flex-col mx-20">
          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.dtmDate} label="Date" />
            <Detail variable={bond.strBondType} label="Bond Type" />
            <Detail variable={bond.strPermitType} label="Permit Type/Obligee" />
            <Detail variable={bond.sngAmount} label="Bond Amount" />
          </div>

          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.strPrincipal} label="Principal" />
            <Detail variable={bond.strTName} label="Trade Name" />
            <Detail variable={bond.strIndemnitor} label="First Indemnitor" />
            <Detail variable={bond.strTitle} label="Title" />
          </div>

          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.str2Indemnitor} label="Second Indemnitor" />
            <Detail variable={bond.strCounty} label="County" />
            <Detail variable={bond.strMailAddr} label="Mail Address" />
            <Detail variable={bond.strMailCity} label="Mail City" />
          </div>

          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.strState} label="State" />
            <Detail variable={bond.strZip} label="ZIP" />
            <Detail variable={bond.strStatus} label="Status" />
            <Detail variable={bond.strReinstate} label="Reinstate" />
          </div>

          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.strApplica} label="Application" />
            <Detail variable={bond.strBondNo} label="Bond No" />
            <Detail variable={bond.strCollateral} label="Collateral" />
            <Detail variable={bond.strCost} label="Cost" />
          </div>

          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.strCheckNo} label="Check No" />
            <Detail variable={bond.strAgent} label="Agent" />
            <Detail variable={bond.strComments} label="Comments" />
            <Detail variable={bond.strCheckRen} label="Check Renewal" />
          </div>
          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.strRenlCost} label="Renewal Cost" />
            <Detail variable={bond.strRenlTerm} label="Renewal Term" />
            <Detail variable={bond.strInvDate} label="Inv Date" />
            <Detail variable={bond.strCancelDt} label="Cancel Date" />
          </div>
          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.sngPremium} label="Premium" />
            <Detail variable={bond.strPaid} label="Paid" />
            <Detail variable={bond.strOverage} label="Extra 1" />
            <Detail variable={bond.strPermitNo} label="Extra 2" />
          </div>
          <div className="flex mb-8 flex-col sm:flex-row">
            <Detail variable={bond.sngTotalPrem} label="Total Premium" />
            <Detail variable={bond.strConsent} label="Underwriting Co." />
            <Detail variable={bond.sngOther} label="Other" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
