import request from '../request.js';

export function getTblBonds(data) {
	return request({
		url: '/tblBonds/getTblBonds',
		method: 'post',
		data
	});
}

export function getAll(data) {
	return request({
		url: '/tblBonds/getAll',
		method: 'post'
	});
}

export function getUserCostCenters(data) {
	return request({
		url: '/users/getCostCenters',
		method: 'post',
		data
	});
}

export function createTblBond(data) {
	return request({
		url: '/tblBonds/createTblBond',
		method: 'post',
		data
	});
}

export function updateTblBond(data) {
	return request({
		url: '/tblBonds/updateTblBond',
		method: 'post',
		data
	})
}

export function deleteBond(data) {
	return request({
		url: '/tblBonds/deleteTblBond',
		method: 'post',
		data
	})
}