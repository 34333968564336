import React, { useEffect } from 'react';
import { Transition } from '@tailwindui/react'
import interfaceState from '../../store/interfaceState';
import { view } from '@risingstack/react-easy-state';

function Notification() {

    let message = interfaceState.notificationMessage;
    let type = interfaceState.notificationType;

    useEffect(() => {
        if (interfaceState.showNotificationVal) {
            setTimeout(() => {
                interfaceState.dissapearNotification();
            }, 5000);
        }
        //eslint-disable-next-line
    }, [interfaceState.showNotificationVal]);


    let icon = <></>;
    let title = "";
    if (type === 'success') {
        icon = <svg className="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />                </svg>;
        title = "Success!"
    }   
    if (type === 'error') {
        icon = <svg className="w-6 h-6 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
        title = "Error!"
    }
    if (type === 'warning') {
        icon = <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        title = "Warning!"
    }

    return (
        //<div className={`${interfaceState.showNotificationVal ? 'visible' : 'hidden'} fixed z-50 inset-0`}>
        <div className="z-50 absolute w-full h-full pointer-events-none">
            <Transition
                show={interfaceState.showNotificationVal}
                enter="transform ease-out duration-500 transition"
                enterFrom="translate-y-12 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
                    <div className="max-w-sm w-full  bg-white shadow-lg rounded-lg pointer-events-auto">
                        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {icon}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm leading-5 font-medium text-gray-900">
                                            {title}
                                        </p>
                                        <p className="mt-1 text-sm leading-5 text-gray-500">
                                            {message}
                                        </p>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button onClick={() => interfaceState.dissapearNotification()} className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    )
}

export default view(Notification);