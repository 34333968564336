import React, { useState } from "react";

import Search from "../Search";
import interfaceState from "../../store/interfaceState";
import { getTblBonds } from "../../api/requests/tblBonds";
import tableState from "../../store/tableState";
import { DatePicker } from "antd";
import "antd/dist/antd.css";

function AdvancedFilters() {
  const [permitType, setPermitType] = useState("");
  const [status, setStatus] = useState("");
  const [bondType, setBondType] = useState("");
  const [reinstate, setReinstate] = useState("");
  const [premium, setPremium] = useState("");
  const [application, setApplication] = useState("");
  const [other, setOther] = useState("");
  const [bondAmount, setBondAmount] = useState("");
  const [totalPremium, setTotalPremium] = useState("");
  const [firstIndemnitor, setFirstIndemnitor] = useState("");
  const [secondIndemnitor, setSecondIndemnitor] = useState("");
  const [collateral, setCollateral] = useState("");
  const [paid, setPaid] = useState("");
  const [county, setCounty] = useState("");
  const [cost, setCost] = useState("");
  const [permitTaxNo, setPermitTaxNo] = useState("");
  const [mailAdress, setMailAdress] = useState("");
  const [checkRenewal, setCheckRenewal] = useState("");
  const [mailCity, setMailCity] = useState("");
  const [renlCost, setRenlCost] = useState("");
  const [tName, setTName] = useState("");
  const [renewalTerm, setRenewalTerm] = useState("");
  const [zip, setZip] = useState("");
  const [principal, setPrincipal] = useState("");
  const [addToReport, setAddToReport] = useState(null);
  const [contacted, setContacted] = useState(null);
  const [title, setTitle] = useState("");
  const [agent, setAgent] = useState("");
  const [bondNo, setBondNo] = useState("");
  const [consent, setConsent] = useState("");
  const [state, setState] = useState("");
  const [checkNo, setCheckNo] = useState("");
  const [comments, setComments] = useState("");
  const [overage, setOverage] = useState("");
  const [oldBondNo, setOldBondNo] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cancelDt, setCancelDt] = useState("");
  const [invDate, setInvDate] = useState("");

  async function handleSearch() {
    try {
      let data = {
        strCancelDt: cancelDt,
        strBondType: bondType,
        strPermitType: permitType,
        sngAmount: parseInt(bondAmount?.match(/\d+/g)?.join('')),
        sngPremium: parseInt(premium?.match(/\d+/g)?.join('')),
        sngOther: parseInt(other?.match(/\d+/g)?.join('')),
        sngTotalPrem: parseInt(totalPremium?.match(/\d+/g)?.join('')),
        strPrincipal: principal,
        strTName: tName,
        strIndemnitor: firstIndemnitor,
        strTitle: title,
        str2Indemnitor: secondIndemnitor,
        strCounty: county,
        strMailAddr: mailAdress,
        strMailCity: mailCity,
        strZip: zip,
        strStatus: status,
        strReinstate: reinstate,
        strApplica: application,
        strBondNo: bondNo,
        strConsent: consent,
        strState: state,
        strCheckNo: checkNo,
        strComments: comments,
        strOverage: overage,
        strCollateral: collateral,
        strCost: cost,
        strCheckRen: checkRenewal,
        strAgent: agent,
        strRenlCost: renlCost,
        strRenlTerm: renewalTerm,
        strUnearned: "",
        strPaid: paid,
        strPermitNo: permitTaxNo,
        bolContacted: contacted,
        bolAddToReport: addToReport,
      };

      let filters = {};
      Object.entries(data).forEach((param) => {
        if (param[1]) {
          if (param[1] !== "") {
            filters[param[0]] = param[1];
          }
        }
      });
      console.log(filters);

      let data2 = { fromDate, toDate, filters };
      let response = await getTblBonds(data2);
      tableState.setFetchedBonds(response.data);
      setLoading(false);
      interfaceState.showNotification({
        show: true,
        type: "success",
        message: `Search complete! Found ${response.data.length} bonds`,
      });
      interfaceState.setMain(true);
      interfaceState.setAdvanced(false);
    } catch (e) {
      console.log(e);
      interfaceState.showNotification({
        show: true,
        type: "error",
        message: "There seems to be an error in the network",
      });
      setLoading(false);
    }
  }

  function loadingIndicator() {
    return (
      <div className="flex text-blue-600">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="flex flex-col max-w-screen-xl mx-auto">
      <div className="w-full flex mb-6 mt-8">
        <div className="ml-8">
          <DatePicker
            defaultValue={null}
            style={{
              height: "auto",
              minWidth: "100%",
              borderRadius: "5px",
              borderColor: "#cbd5e0",
              cursor: "pointer",
              fontSize: "17px",
            }}
            size={"large"}
            placeholder={"From Date"}
            onChange={(e) => setFromDate(e.format("YYYY-MM-DD 00:00"))}
          />
        </div>
        <div className="ml-2">
          <DatePicker
            defaultValue={null}
            style={{
              height: "auto",
              minWidth: "100%",
              borderRadius: "5px",
              borderColor: "#cbd5e0",
              cursor: "pointer",
              fontSize: "17px",
            }}
            size={"large"}
            placeholder={"To Date"}
            onChange={(e) => setToDate(e.format("YYYY-MM-DD 23:59"))}
          />
        </div>
        <button
          type="button"
          className="ml-auto  w-2/12 justify-center inline-flex items-center px-8 border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition ease-in-out duration-150"
          onClick={() => {
            setLoading(true);
            handleSearch();
          }}
        >
          {loading ? loadingIndicator() : "SEARCH"}
        </button>
        <button
          onClick={() => {
            interfaceState.setAdvanced(false);
          }}
          className="w-2/12 max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <p className="ml-3 py-1 text-blue-700 text-sm leading-5 font-medium lg:block">
            Less filters
          </p>
          <svg
            className="flex-shrink-0 ml-1 h-5 w-5 text-blue-700 lg:block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="flex w-full content-center sm:flex-row pb-6">
      <div className="w-1/2">
          <Search variable={bondType} action={setBondType} label="Bond Type" />
          <Search variable={permitType} action={setPermitType} label="Permit Type/Obligee"        />
          <Search variable={bondAmount} action={setBondAmount} label="Bond Amount"        />
          <Search variable={principal} action={setPrincipal} label="Principal" />
          <Search variable={tName} action={setTName} label="Trade Name" />
          <Search variable={firstIndemnitor} action={setFirstIndemnitor} label="First Indemnitor"        />
          <Search variable={title} action={setTitle} label="Title" />
          <Search variable={secondIndemnitor} action={setSecondIndemnitor} label="Second Indemnitor"        />
          <Search variable={county} action={setCounty} label="County" />
          <Search variable={mailAdress} action={setMailAdress} label="Mail Adress"        />
          <Search variable={mailCity} action={setMailCity} label="Mail City" />
          <Search variable={state} action={setState} label="State" />
          <Search variable={zip} action={setZip} label="Zip" />
          <Search variable={status} action={setStatus} label="Status" />
          <Search variable={reinstate} action={setReinstate} label="Reinstate" />
          <Search variable={application}  action={setApplication} label="Application"        />
          <Search variable={bondNo} action={setBondNo} label="Bond No" />
        </div>
        <div className="w-1/2">
          <Search variable={collateral} action={setCollateral} label="Collateral"        />
          <Search variable={cost} action={setCost} label="Cost" />
          <Search variable={checkNo} action={setCheckNo} label="Check No" />
          <Search variable={agent} action={setAgent} label="Agent" />
          <Search variable={comments} action={setComments} label="Comments" />
          <Search variable={checkRenewal} action={setCheckRenewal} label="Check Renewal"        />
          <Search variable={renlCost} action={setRenlCost} label="Renl Cost" />
          <Search variable={renewalTerm} action={setRenewalTerm} label="Renewal term"        />
          <Search variable={invDate} action={setInvDate} label="Inv Date" />
          <Search variable={cancelDt} action={setCancelDt} label="Cancel Date"        />
          <Search variable={premium} action={setPremium} label="Premium" />

          <Search variable={other} action={setOther} label="Other" />
          <Search variable={totalPremium} action={setTotalPremium} label="Total Premium"        />
          <Search variable={paid} action={setPaid} label="Paid" />
          <Search variable={oldBondNo} action={setOldBondNo} label="Permit Tax No" />
          <Search variable={permitTaxNo} action={setPermitTaxNo} label="Extra 2"        />
          <Search variable={overage}  action={setOverage} label="Extra 1"        />
          <Search variable={consent} action={setConsent} label="Underwriting Co" />
        </div>
      </div>
    </div>
  );
}

export default AdvancedFilters;
