import React, { useState } from "react";
import logo from "../../assets/images/logoWname.png";
import background from "../../assets/images/background.png";
import firebase from "firebase";
import interfaceState from "../../store/interfaceState";

export default function Login({ setAuthComponent }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  function handleLogin() {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        interfaceState.showNotification({
          show: true,
          type: "success",
          message: "Succesfully logged in",
        });
      })
      .catch(function (error) {
        setLoading(false);
        interfaceState.showNotification({
          show: true,
          type: "error",
          message: error.message,
        });
      });
  }

  let loginBtnLoading = (
    <button
      type="button"
      className="w-full justify-center inline-flex items-center py-3 px-0  border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition ease-in-out duration-150 cursor-not-allowed"
    >
      <svg
        className="animate-spin  h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </button>
  );

  let loginBtn = (
    <button
      onClick={() => handleLogin()}
      type="submit"
      className="group relative w-full flex justify-center py-3 px-4  border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out"
    >
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        <svg
          className="h-5 w-5 text-blue-400 group-hover:text-blue-200 transition ease-in-out duration-150"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      Log in
    </button>
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <img
        className={"absolute z-0 bg-fixed h-full w-full bg-cover"}
        src={background}
        alt="AAA Surety background"
      />

      <div className="z-10">
        <div className=" mr-auto  ml-auto rounded-xl shadow-md bg-white">
          <div className="w-full mx-auto text-center">
            <img
              className={"w-48 pt-4 mx-auto"}
              src={logo}
              alt="AAA Surety  Logo"
            />
          </div>
          <div className="w-full max-w-md mr-auto ml-auto mt-4">
            <div className=" rounded-md px-8 py-8 mb-4 ml-auto mr-auto">
              <div className="">
              <label
              style={{marginTop: -10, marginLeft:15, paddingLeft: 5,paddingRight:5}}
                className="absolute bg-white block text-gray-700 text-sm font-medium mb-2"
                htmlFor="username"
              >
                Email
              </label>
                <input
                onChange={(e) => setEmail(e.target.value)}
                className="shadow-sm appearance-none border border-gray-300 rounded-md w-full py-3 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:border-blue-300"
                id="username"
                type="text"
                placeholder=""
              />
              </div>
              
              <div className="mt-4">
              <label
              style={{marginTop: -10, marginLeft:15, paddingLeft: 5,paddingRight:5}}
                className="absolute bg-white block text-gray-700 text-sm font-medium mb-2"
                htmlFor="username"
              >
                Password
              </label>
                <input
                onChange={(e) => setPassword(e.target.value)}
                className="shadow-sm appearance-none border border-gray-300 rounded-md w-full py-3 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:border-blue-300"
                id="username"
                type="password"
                placeholder=""
              />
              </div>

              <div className="mb-6 mt-6">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="text-center sm:text-left">
                    <label>
                      <input type="checkbox" className="mr-2" />
                      <span className="text-sm font-medium text-gray-700 ">
                        Remember me
                      </span>
                    </label>
                  </div>

                  <div className="text-center sm:text-right">
                    <button
                      
                      onClick={() => setAuthComponent(2)}
                      className="text-blue-600 font-medium text-sm duration-200 transition-colors hover:text-blue-800"
                    >
                      Forgot your password?
                    </button>
                  </div>
                </div>
              </div>
              {loading ? loginBtnLoading : loginBtn}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
