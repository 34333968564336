import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";

import Search from "../Search";
import interfaceState from "../../store/interfaceState";
import { getTblBonds, updateTblBond } from "../../api/requests/tblBonds";
import tableState from "../../store/tableState";

function Edit() {
  const { bond } = interfaceState;

  function loadingIndicator() {
    return (
      <div className="flex text-blue-600">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {"‏‏‎ ‎"}
      </div>
    );
  }

  useEffect(() => {
    setCancelDt(bond.strCancelDt);
    setDate(bond.dtmDate);
    setBondType(bond.strBondType);
    setPermitType(bond.strPermitType);
    setBondAmount(bond.sngAmount);
    setPrincipal(bond.strPrincipal);
    setTName(bond.strTName);
    setFirstIndemnitor(bond.strIndemnitor);
    setCounty(bond.strCounty);
    setMailAdress(bond.strMailAddr);
    setMailCity(bond.strMailCity);
    setZip(bond.strZip);
    setStatus(bond.strStatus);
    setReinstate(bond.strReinstate);
    setApplication(bond.strApplica);
    setCollateral(bond.strCollateral);
    setCheckRenewal(bond.strCheckRen);
    setRenlCost(bond.strRenlCost);
    setRenewalTerm(bond.strRenlTerm);
    setUnearned(bond.strUnearned);
    setPremium(bond.sngPremium);
    setTotalPremium(bond.sngTotalPrem);
    setOther(bond.sngOther);
    setPaid(bond.strPaid);
    setPermitTaxNo(bond.strPermitNo);
    setCost(bond.strCost);
    setAddToReport(bond.bolAddToReport);
    setSecondIndemnitor(bond.str2Indemnitor);
    setTotalPremium(bond.sngTotalPrem);
    setContacted(bond.bolContacted);
    setTitle(bond.strTitle);
    setAgent(bond.strAgent);
    setBondNo(bond.strBondNo);
    setConsent(bond.strConsent);
    setState(bond.strState);
    setCheckNo(bond.strCheckNo);
    setComments(bond.strComments);
    setOverage(bond.strOverage);
    setOldBondNo(bond.strOldBondNo);
    setInvDate(bond.strInvDate);
    //eslint-disable-next-line
  }, []);

  const [permitType, setPermitType] = useState("");
  const [status, setStatus] = useState("");
  const [unearned, setUnearned] = useState("");
  const [bondType, setBondType] = useState("");
  const [reinstate, setReinstate] = useState("");
  const [premium, setPremium] = useState("");
  const [application, setApplication] = useState("");
  const [other, setOther] = useState("");
  const [bondAmount, setBondAmount] = useState("");
  const [totalPremium, setTotalPremium] = useState("");
  const [firstIndemnitor, setFirstIndemnitor] = useState("");
  const [secondIndemnitor, setSecondIndemnitor] = useState("");
  const [collateral, setCollateral] = useState("");
  const [paid, setPaid] = useState("");
  const [county, setCounty] = useState("");
  const [cost, setCost] = useState("");
  const [permitTaxNo, setPermitTaxNo] = useState("");
  const [mailAdress, setMailAdress] = useState("");
  const [checkRenewal, setCheckRenewal] = useState("");
  const [mailCity, setMailCity] = useState("");
  const [renlCost, setRenlCost] = useState("");
  const [tName, setTName] = useState("");
  const [renewalTerm, setRenewalTerm] = useState("");
  const [zip, setZip] = useState("");
  const [principal, setPrincipal] = useState("");
  const [addToReport, setAddToReport] = useState(null);
  const [contacted, setContacted] = useState(null);
  const [title, setTitle] = useState("");
  const [agent, setAgent] = useState("");
  const [bondNo, setBondNo] = useState("");
  const [consent, setConsent] = useState("");
  const [state, setState] = useState("");
  const [checkNo, setCheckNo] = useState("");
  const [comments, setComments] = useState("");
  const [overage, setOverage] = useState("");
  const [oldBondNo, setOldBondNo] = useState("");
  const [invDate, setInvDate] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [cancelDt, setCancelDt] = useState("");

  async function handleEdit() {
    try {
      let tmpSngAmount = parseFloat(bondAmount.toString().replace('$', '').replace(',', ''));
      let tmpSngPremium = parseFloat(premium?.toString().replace('$', '').replace(',', ''));
      let tmpSngOther = parseFloat(other?.toString().replace('$', '').replace(',', ''));
      let tmpSngTotalPrem = parseFloat(totalPremium?.toString().replace('$', '').replace(',', ''));
      if (!tmpSngAmount) {
        tmpSngAmount = 0;
      }
      if (!tmpSngPremium) {
        tmpSngPremium = 0;
      }
      if (!tmpSngOther) {
        tmpSngOther = 0;
      }
      if (!tmpSngTotalPrem) {
        tmpSngTotalPrem = 0;
      }
      let data = {
        strSuzy: bond.strSuzy,
        dtmDate: date,
        strInvDate: invDate,
        strCancelDt: cancelDt,
        strBondType: bondType,
        strPermitType: permitType,
        sngAmount: tmpSngAmount,
        sngPremium: tmpSngPremium,
        sngOther: tmpSngOther,
        sngTotalPrem: tmpSngTotalPrem,
        strPrincipal: principal,
        strTName: tName,
        strIndemnitor: firstIndemnitor,
        strTitle: title,
        str2Indemnitor: secondIndemnitor,
        strCounty: county,
        strMailAddr: mailAdress,
        strMailCity: mailCity,
        strZip: zip,
        strStatus: status,
        strReinstate: reinstate,
        strApplica: application,
        strBondNo: bondNo,
        strConsent: consent,
        strState: state,
        strCheckNo: checkNo,
        strComments: comments,
        strOverage: overage,
        strCollateral: collateral,
        strCost: cost,
        strCheckRen: checkRenewal,
        strAgent: agent,
        strRenlCost: renlCost,
        strRenlTerm: renewalTerm,
        strUnearned: unearned,
        strPaid: paid,
        strPermitNo: permitTaxNo,
        bolContacted: contacted,
        bolAddToReport: addToReport,
        strOldBondNo: oldBondNo,
        strInvDate: invDate,
      };

      let result = {};
      let bondEntries = Object.entries(bond);
      Object.entries(data).forEach((param) => {
        bondEntries.forEach((element) => {
          if (param[0] === element[0]) {
            if (param[0] === "sngAmount") {
              console.log(param[1]);
              console.log(element[1]);
            }

            if (param[1] !== element[1]) {
              result[param[0]] = param[1];
            }
          }
        });
      });

      let dataWithId = { id: bond.pkID, data: result };

      await updateTblBond(dataWithId);
      setLoading(false);
      interfaceState.showNotification({
        show: true,
        type: "success",
        message: `Bond succesfully updated!`,
      });
      let data2 = { filters: {} };
      let response2 = await getTblBonds(data2);
      tableState.setFetchedBonds(response2.data);
      interfaceState.updateBond(result);
      interfaceState.setEdit(false);
    } catch (e) {
      console.log(e);
      interfaceState.showNotification({
        show: true,
        type: "error",
        message: "There seems to be an error in the network",
      });
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col max-w-screen-xl mx-auto pb-6">
      <nav
        className="cursor-pointer flex my-8 ml-8 mr-8"
        aria-label="Breadcrumb"
      >
        <ol
          onClick={() => interfaceState.setEdit(false)}
          className="flex items-center space-x-4"
        >
          <li>
            <div>
              <button className="text-gray-400 hover:text-gray-500">
                <svg
                  className="flex-shrink-0 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <button className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                Back to details
              </button>
            </div>
          </li>
        </ol>
        <button
          type="button"
          className="ml-auto  w-2/12 justify-center inline-flex items-center py-2 px-8 border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition ease-in-out duration-150"
          onClick={() => {
            setLoading(true);
            handleEdit();
          }}
        >
          {loading ? loadingIndicator() : "UPDATE"}
        </button>
      </nav>

      <div className="flex w-full content-center sm:flex-row">
        <div className="w-1/2">
          <div className="block relative mt-4 w-11/12 mx-1 sm:mx-8">
            <label
              style={{
                marginTop: -10,
                marginLeft: 15,
                paddingLeft: 5,
                paddingRight: 5,
                zIndex: 100,
              }}
              className="absolute bg-white block text-gray-700 text-sm font-medium mb-2"
            >
              Date
            </label>
            <DatePicker
              defaultValue={null}
              style={{
                height: "auto",
                minWidth: "100%",
                borderRadius: "5px",
                borderColor: "#cbd5e0",
                cursor: "pointer",
                fontSize: "17px",
              }}
              size={"large"}
              placeholder={"From Date"}
              defaultValue={moment(bond.dtmDate, "YYYY-MM-DD")}
              onChange={(e) => setDate(e.format("YYYY-MM-DD 12:00"))}
            />
          </div>
          <Search variable={bondType} action={setBondType} label="Bond Type" />
          <Search
            variable={permitType}
            action={setPermitType}
            label="Permit Type/Obligee"
          />
          <Search
            variable={bondAmount}
            action={setBondAmount}
            label="Bond Amount"
          />
          <Search
            variable={principal}
            action={setPrincipal}
            label="Principal"
          />
          <Search variable={tName} action={setTName} label="Trade Name" />
          <Search
            variable={firstIndemnitor}
            action={setFirstIndemnitor}
            label="First Indemnitor"
          />
          <Search variable={title} action={setTitle} label="Title" />
          <Search
            variable={secondIndemnitor}
            action={setSecondIndemnitor}
            label="Second Indemnitor"
          />
          <Search variable={county} action={setCounty} label="County" />
          <Search
            variable={mailAdress}
            action={setMailAdress}
            label="Mail Adress"
          />
          <Search variable={mailCity} action={setMailCity} label="Mail City" />
          <Search variable={state} action={setState} label="State" />
          <Search variable={zip} action={setZip} label="Zip" />
          <Search variable={status} action={setStatus} label="Status" />
          <Search
            variable={reinstate}
            action={setReinstate}
            label="Reinstate"
          />
          <Search
            variable={application}
            action={setApplication}
            label="Application"
          />
          <Search variable={bondNo} action={setBondNo} label="Bond No" />
        </div>
        <div className="w-1/2">
          <Search
            variable={collateral}
            action={setCollateral}
            label="Collateral"
          />
          <Search variable={cost} action={setCost} label="Cost" />
          <Search variable={checkNo} action={setCheckNo} label="Check No" />
          <Search variable={agent} action={setAgent} label="Agent" />
          <Search variable={comments} action={setComments} label="Comments" />
          <Search variable={renlCost} action={setRenlCost} label="Renl Cost" />
          <Search
            variable={checkRenewal}
            action={setCheckRenewal}
            label="Check Renewal"
          />
          <Search
            variable={renewalTerm}
            action={setRenewalTerm}
            label="Renewal term"
          />
          <Search variable={invDate} action={setInvDate} label="Inv Date" />
          <Search
            variable={cancelDt}
            action={setCancelDt}
            label="Cancel Date"
          />
          <Search variable={premium} action={setPremium} label="Premium" />
         
          <Search variable={other} action={setOther} label="Other" />
          <Search
            variable={totalPremium}
            action={setTotalPremium}
            label="Total Premium"
          />
          <Search variable={paid} action={setPaid} label="Paid" />
          <Search
            variable={oldBondNo}
            action={setOldBondNo}
            label="Permit Tax No"
          />
          <Search variable={overage} action={setOverage} label="Extra 1" />
          <Search
            variable={permitTaxNo}
            action={setPermitTaxNo}
            label="Extra 2"
          />
          <Search
            variable={consent}
            action={setConsent}
            label="Underwriting Co"
          />
        </div>
      </div>
    </div>
  );
}

export default Edit;
