import React, {  useEffect } from 'react';
import logo from '../../assets/images/logoWname.png';
import authState from '../../store/authState';
import { view } from '@risingstack/react-easy-state';
import background from "../../assets/images/background.png";

function Loading({ setAuthComponent }) {


    useEffect(() => {
        if (authState.currentUser) {
            setAuthComponent(0);
        } else {
            setAuthComponent(0);
        }
    }, [authState.currentUser])

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                  <img
            className={"absolute z-0 bg-fixed h-full w-full bg-cover"}
            src={background}
            alt="AAA Surety background"
          />

            <div className="mr-auto z-10 ml-auto w-96 shadow rounded-xl bg-white">
                <div className="w-full max-w-md mr-auto ml-auto mb-1 text-center">
                    <img className={"h-24 mt-4 mx-auto"} src={logo} alt="TCOR Logo" />
                </div>
                <div className="w-full max-w-md mr-auto ml-auto mt-4">
                    <div className=" rounded-md px-8 py-8 mb-4 ml-auto mr-auto">
                        <button
                            type="button"
                            className="w-full disabled justify-center inline-flex items-center py-3 px-0  border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition ease-in-out duration-150 cursor-not-allowed">
                            <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default view(Loading);