import React from 'react'
import Router from './routes/';
import './App.css'

function App() {
  return (
    <Router/>
  )
}

export default App
